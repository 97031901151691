const facilityTop = {
	mobileToggle: document.querySelector('#toggle_fac_info'),
	info: document.querySelector('#facility_info_container'),
	mobileClick: function() {
		this.mobileToggle.switchAttribute('state', 'closed', 'open');
		this.info.switchAttribute('state', 'closed', 'open');
	},
	init: function() {
		this.mobileToggle.addEventListener('click', () => this.mobileClick());
	}
};


const facilityFilters ={
	filters: document.querySelectorAll('#rates_filter_sizes button'),
	rows: document.querySelectorAll('.rates_row'),
	filterClick: function(e){
		const clickedFilter = e.target.closest('button');

		if(clickedFilter){
			clickedFilter.toggleAttribute('active');

			const activeFilters = [...document.querySelectorAll('#rates_filter_sizes button[active]')].map(filter => filter.getAttribute('size'));

			this.rows.forEach(row => {
				row.setAttribute('hide', '');

				if(!activeFilters.length || activeFilters.includes(row.getAttribute('size'))) {
					row.removeAttribute('hide');
				}
			});
		}
	},
	init: function(){
		document.querySelector('#rates_filter_sizes').addEventListener('click', e => this.filterClick(e));
	}
};

export const initFacilityFilter = () => facilityFilters.init();
export const initFacilityTop = () => facilityTop.init();