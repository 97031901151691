import './polyfills.js';
import { header } from './header.js';

import { contactSubmit } from './contact.js';
import { initFacilityTop, initFacilityFilter } from './facility.js';
import { initMultiMap } from './multiMap.js';

if (document.getElementById('contact_form')) {
	contactSubmit();
}

header.init();

if(document.querySelector('#facility')) {
	initFacilityTop();
}

if(document.querySelector('#rates_filter_sizes')) {
	initFacilityFilter();
}

// Multi Map
if(document.querySelector('#locations_map')) {
	initMultiMap();
}
