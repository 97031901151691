export const header = (function(){
	let my = {};

	function _toggleMenu(){
		document.getElementById('header_menu').classList.toggle('open');
		document.getElementById('header_nav').classList.toggle('open');
	}

	function _addListener(){
		document.getElementById('header_menu').addEventListener('click', _toggleMenu);
	}

	my.init = function(){
		var dClick = document.querySelectorAll('.d_click');
		[].forEach.call(dClick, function(el){
			el.addEventListener('click', function (e) {
				if(window.innerWidth > 750) {
					if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
						if (e.currentTarget.dataset.clicks < 1){
							e.preventDefault();
						}
					}
				}
				e.currentTarget.dataset.clicks = 1;
			});
		});
		_addListener();
	};

	return my;
})();