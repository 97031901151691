/* global google */
import { getMapsHelper } from './util/mapsHelper.js';

const multiMap = {
	theMap: {
		ele: document.querySelector('#locations_map'),
		instance: null
	},
	init: async function() {
		const mapsHelper = getMapsHelper();

		await mapsHelper.ready();

		this.theMap.instance = mapsHelper.createMap({
			element: this.theMap.ele,
			locationElementSelector: '.location_info',
			useRichmarker: true,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: 
                        `<a href="${el.getAttribute('link')}" class="map_pin">
                            <span>${el.getAttribute('num')}</span>
                            <img src="/dist/images/map_pin.svg" alt="" />
                        </a>`
				};
			}
		});

		google.maps.event.addListenerOnce(this.theMap.instance, 'idle', () => {

			document.querySelectorAll('.location_info').forEach(i => {
				const markerData = mapsHelper.getMarker(i);
				markerData.element.addEventListener('mouseenter', () => {
					markerData.element.classList.add('hover');
					i.classList.add('hover');
				});
				markerData.element.addEventListener('mouseleave', () => {
					markerData.element.classList.remove('hover');
					i.classList.remove('hover');
				});
			});
		});
	}
};

export const initMultiMap = () => multiMap.init();